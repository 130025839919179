<ng-container *ngIf="!lndUserType && !loaderService.getLoading()">
    <div class="row">
       <div class="col" style="margin: 15vh 15vw 1vh; color: #777; font-size: 3vh; text-align: center;">
          Oops! It seems you are logged out. <br>
          Please Login again to continue :)
       </div>
    </div>
    <div class="row">
       <div class="col" style="margin: 1vh 15vw 15vh; text-align: center;">
          <button class="btn btn-primary" routerLink="/login" style="width: 30vw;">Go to Login</button>
       </div>
    </div>
 </ng-container>
 <span *ngIf="lndUserType">
 <app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)"
 (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)"(notifyshowEseparation)="showEseparation($event)" (notifyshowNDC)="showNDCBtn($event)" 
 ></app-header-global>
 </span>
 <div class="row allcontent" *ngIf="lndUserType">
    <div class="col-sm-2">
       <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr" [showpoa]="showPOA"
       [showamdoc]="showAmdoc"  [showEsep]="showesep" [showNDCBtn]="showndcBtn"
       ></app-side-overlay-menu>
    </div>
    <div class="col-sm-10 contentsection">
       <div class="row">
          <div class="col header">
             Profile
          </div>
       </div>
       <div class="row profile-card" *ngIf="emp">
          <div class="col paddings">
             <div class="row">
                <div class="col key">
                   Employee ID
                </div>
                <div class=" col-4 value">
                   {{emp.emp_id}}
                </div>
                <div class="col key">
                   Full Name
                </div>
                <div class=" col-4 value">
                   {{emp.name |titlecase}}
                </div>
             </div>
             <div class="row">
                <div class="col key">
                   Gender
                </div>
                <div class=" col-4 value">
                   {{emp.gender |titlecase}}
                </div>
                <div class="col key">
                   Designation
                </div>
                <div class=" col-4 value">
                   {{emp.job_title}}
                </div>
             </div>
             <div class="row">
                <div class="col key">
                   Department
                </div>
                <div class=" col-4 value">
                   {{emp.department |titlecase}}
                </div>
                <div class="col key">
                   Business
                </div>
                <div class=" col-4 value">
                   {{emp.business |titlecase}}
                </div>
             </div>
             <div class="row">
                <div class="col key">
                   Mobile No.
                </div>
                <div class=" col-4 value">
                   {{emp.mobile_no}}
                </div>
                <div class="col key">
                   Email
                </div>
                <div class=" col-4 value">
                   {{emp.email}}
                </div>
             </div>
             <div class="row">
                <div class="col key">
                   Date of Birth
                </div>
                <div class=" col-4 value">
                   {{emp.dob}}
                </div>
                <div class="col key">
                   Date of Joining
                </div>
                <div class=" col-4 value">
                   {{emp.doj}}
                </div>
             </div>
             <div class="row">
                <div class="col key">
                   Qualification(UG)
                </div>
                <div class="col-4 value">
                   {{emp.qualification_ug}}
                </div>
                <div class="col key">
                   Qualification(PG)
                </div>
                <div class="col-4 value">
                   {{emp.qualification_pg}}
                </div>
             </div>
             <div class="row">
                <div class="col key">
                   Reporting Manager
                </div>
                <div class=" col-4 value">
                   {{emp.manager}}: {{emp.manager_name |titlecase}}
                </div>
                <div class="col key">
                   <!-- <button *ngIf="canSubmit" type="button" class="btn e-SeparationBtn" 
                      data-bs-toggle="modal" data-bs-target="#e-Separation">
                      E - Separation
                      </button>
                      <button *ngIf="!canSubmit" type="button" class="btn e-SeparationBtn" (click)="showAlert()">
                      E - Separation
                      </button> -->
                </div>
                <div class="col-4 value">
                </div>
             </div>
             <div class="row">
                <div class="col-3 promotion" (click)="getPromotions()">
                   Promotion History
                </div>
             </div>
          </div>
       </div>
       <div  class="col-sm-12 exitProcessBg">
       </div>
       <!-- *ngIf="currentStep > 0" -->
       <div  class="col-sm-12 ">
          <div class="row stepsBg exitProcess">
             <div *ngIf="!canSubmit"  class="col-sm-10 exitProcess">



                <p class="heading_go">Your Formal Exit process has started! Thanku for being part of our Journey!</p>
                <p *ngIf="!canSubmit && !canWithdraw">Important: Please upload all relevant documents before initiating your exit process to ensure a smooth transition.</p>
             </div>
             <div *ngIf="canSubmit" class="col-sm-10 exitProcess">
                <p class="heading_go">Start your formal exit process</p>
               
             </div>
             <div  class="col-sm-2 bunnetDiv">
                <!-- (click)="showAlert() -->
                <!-- Show button when "can_submit" is true and "can_withdraw" is false -->
                <button *ngIf="canSubmit && !canWithdraw" 
                   type="button" 
                   class="btn e-SeparationBtn" 
                   data-bs-toggle="modal" 
                   data-bs-target="#e-Separation">
                E - Separation
                </button>
                <!-- Show button when "can_submit" is false and "can_withdraw" is true -->
                <button
                *ngIf="!canSubmit && canWithdraw"
                type="button"
                class="btn e-SeparationBtn"
                data-bs-toggle="modal"
                data-bs-target="#withdrawalReasonModal"
                >
                Withdraw application
                </button>

                
                <!--   Show disabled button when both "can_submit" and "can_withdraw" are false -->
                <button *ngIf="!canSubmit && !canWithdraw"  
                   type="button" 
                   class="btn e-SeparationBtn" 
                   matTooltip="Candidates who wish to withdraw their application after the second level of HR are requested to formally notify the HR department of their decision" 
                   matTooltipPosition="above"
                   matTooltipClass="custom-tooltip">
                E - Separation
                </button>
             </div>
          </div>
          <!--  both  *ngIf="!canSubmit && !canWithdraw"  button not click able 
             both  *ngIf="!canSubmit && !canWithdraw"  button not click able  -->
          <div class="stepContainer">
             <ng-container *ngFor="let step of steps">
                <!-- Circle with dynamic class and check mark for approved status -->
                <div [ngClass]="['circle', step.status]">
                   <ng-container *ngIf="step.status === 'approved'">
                      <span class="unicode-check">
                         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                         </svg>
                      </span>
                      <!-- Unicode check mark -->
                   </ng-container>
                   <ng-container *ngIf="step.status !== 'approved'">
                      {{ step.index }}
                   </ng-container>
                </div>
                <!-- Line between circles -->
                <!-- Step name -->
                <div [ngClass]="['text', step.status]">{{ step.name }}</div>
                <div *ngIf="step.index < steps.length" [ngClass]="['line', step.status]">
          </div>
          </ng-container>
       </div>
    </div>
    <!-- *ngIf="currentStep === 6" -->
    <!-- (click)="viewDownloadPdf(document.file)" -->
    <div *ngIf="!canSubmit && !canWithdraw" class="col-12 interviewForm">
       <table>
          <tr>
             <th class="formType">Types of Forms</th>
             <th   class="downloadForm">Download</th>
             <th  class="uploadForm">Upload</th>
          </tr>
          <tr>
             <td>Exit Interview Form</td>
             <td>
                <a (click)="viewDownloadPdf(documents[7].file)" class="downloadBtn">
                   <span class="downloadIcon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                         <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                         <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                      </svg>
                   </span>
                   Download Template
                </a>
             </td>
             <td>
                <a class="uploadBtn" (click)="fileInput3.click()">
                   <span class="uploadIcon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                         <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                         <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                      </svg>
                   </span>
                   Upload Document
                </a>
                <input type="file" #fileInput3 style="display: none;" accept=".pdf,.doc,.docx" (change)="onFileSelected($event, 'interviewForm')" />
                <span class="fileName" *ngIf="interviewForm"> <a [href]="interviewFormURL" target="_blank"> {{ interviewForm }}</a></span>
                <!-- <button *ngIf="interviewForm" class="remove-btn" (click)="removeFile('interviewForm')">×</button> -->
             </td>
          </tr>
          <tr *ngIf="!isTemp">
             <td>Gratuity & Leave Incashment Form</td>
             <td>
                <a (click)="viewDownloadPdf(documents[8].file)" class="downloadBtn">
                   <span class="downloadIcon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                         <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                         <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                      </svg>
                   </span>
                   Download Template
                </a>
             </td>
             <td>
                <a class="uploadBtn" (click)="fileInput2.click()">
                   <span class="uploadIcon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                         <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                         <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                      </svg>
                   </span>
                   Upload Document
                </a>
                <input type="file" #fileInput2 style="display: none;" accept=".pdf,.doc,.docx" (change)="onFileSelected($event, 'gratuityLeaveIncashment')" />
                <span class="fileName" *ngIf="gratuityLeaveIncashment"> <a [href]="gratuityLeaveIncashmentURL" target="_blank"> {{ gratuityLeaveIncashment }}</a>
                </span>
                <!-- <button *ngIf="gratuityLeaveIncashment" class="remove-btn" (click)="removeFile('gratuityLeaveIncashment')">×</button> -->
             </td>
          </tr>
          <tr *ngIf="!isTemp">
             <td>Investment declaration form with proof</td>
             <td>
                <a (click)="viewDownloadPdf(documents[9].file)" class="downloadBtn">
                   <span class="downloadIcon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                         <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                         <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                      </svg>
                   </span>
                   Download Template
                </a>
             </td>
             <td>
                <a class="uploadBtn" (click)="fileInput1.click()">
                   <span class="uploadIcon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                         <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                         <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                      </svg>
                   </span>
                   Upload Document
                </a>
                <input type="file" #fileInput1 style="display: none;" accept=".pdf,.doc,.docx" (change)="onFileSelected($event, 'investmentDeclaration')" />
                <span class="fileName" *ngIf="investmentDeclaration"><a [href]="investmentDeclarationURL" target="_blank"> {{ investmentDeclaration }}</a></span>
                <!-- <button *ngIf="investmentDeclaration" class="remove-btn" (click)="removeFile('investmentDeclaration')">×</button> -->
             </td>
          </tr>
       </table>
    </div>
    <!-- *ngIf="currentStep === 6" -->
    <div  class="col-12" *ngIf="!canSubmit && !canWithdraw">
       <p></p>
       <p>
          <span class="red">*</span> All documents should be in PDF format with a maximum size of 25 MB. Please upload your relevant documents before your exit date
       </p>
    </div>
 </div>
 </div>
 <br><br>
 <div class="modal fade" id="e-Separation" tabindex="-1" aria-labelledby="e-SeparationLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
       <div class="modal-content">
          <div class="modal-header">
             <h1 class="modal-title fs-5" id="e-SeparationLabel">E - Separation</h1>
             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
             <form [formGroup]="exitForm">
                <div class="row">
                   <div class="col-12">
                      <textarea required formControlName="exitReason" class="form-control" id="message-text"
                         rows="8" placeholder=""></textarea>
                      <div
                         *ngIf="exitForm.get('exitReason').invalid && (exitForm.get('exitReason').dirty || exitForm.get('exitReason').touched)">
                         <small *ngIf="exitForm.get('exitReason').hasError('required')" class="text-danger">
                         Exit reason is required.
                         </small>
                      </div>
                   </div>
                </div>
                <div class="row userEnter">
                   <div class="col-12">
                      <h1 class="modal-title fs-5" id="e-SeparationLabel">Contact Information </h1>
                   </div>
                   <div class="col-6 whatsAppCol">
                      <label>Phone No.</label>
                      <input
                         formControlName="whatsAppNumber"
                         type="text"
                         class="form-control"
                         id="whatsApp-number"
                         required
                         maxlength="10"
                         pattern="^\d{10}$"
                         placeholder="WhatsApp No."
                         oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                         >
                      <div *ngIf="exitForm.get('whatsAppNumber').invalid && (exitForm.get('whatsAppNumber').dirty || exitForm.get('whatsAppNumber').touched)">
                         <!-- Show "Cannot start with zero" error first -->
                         <small *ngIf="exitForm.get('whatsAppNumber').hasError('noLeadingZero')" class="text-danger">
                         WhatsApp number cannot start with zero.
                         </small>
                         <!-- Show "Must be 10 digits" error only if the number doesn't start with zero -->
                         <small *ngIf="!exitForm.get('whatsAppNumber').hasError('noLeadingZero') && exitForm.get('whatsAppNumber').hasError('pattern')" class="text-danger">
                         Must be a 10-digit number.
                         </small>
                      </div>
                   </div>
                   <div class="col-6">
                      <label>E-Mail ID</label>
                      <input 
                         formControlName="personalEmail"  placeholder="Personal e-mail Id"
                         type="email" 
                         class="form-control" 
                         id="personal-email" 
                         required 
                         pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" 
                         >
                      <div
                         *ngIf="exitForm.get('personalEmail').invalid && (exitForm.get('personalEmail').dirty || exitForm.get('personalEmail').touched)">
                         <small *ngIf="exitForm.get('personalEmail').hasError('required')" class="text-danger">
                         Email is required.
                         </small>
                         <small *ngIf="exitForm.get('personalEmail').hasError('email')" class="text-danger">
                         Must be a valid email address.
                         </small>
                      </div>
                   </div>
                </div>
             </form>
          </div>
          <div class="modal-footer-e-Separation">
             <div class="row">
                <div class="col-6" style="font-weight: 500;">
                   <span class="exitDate">Exit Date : {{noticePeriod}}</span>
                </div>
                <div class="col-6 bottomBtn">
                   <button type="button" class="btn acknowledge" (click)="submitForm()" [disabled]="exitForm.invalid"
                   data-bs-dismiss="modal">
                   Submit
                   </button>
                   <button type="button" class="btn reject" data-bs-dismiss="modal">Cancel</button>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
 <!-- Withdrawal Reason Modal -->
<div class="modal fade" id="withdrawalReasonModal" tabindex="-1" aria-labelledby="withdrawalReasonModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="withdrawalReasonModalLabel">Reason for Exit Withdrawal</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="withdrawalForm">
            <div class="mb-3">
              <label for="withdrawalReason" class="form-label">Reason</label>
              <textarea
                class="form-control"
                id="withdrawalReason"
                formControlName="withdrawalReason"
                rows="3"
                placeholder="Enter reason for withdrawal"
              ></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="submitWithdrawal()">Submit</button>
        </div>
      </div>
    </div>
  </div>