import { Component, OnInit,ChangeDetectorRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LearnerService } from "src/app/core/services/learner.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { ESeparationService } from "src/app/core/services/e-separation.service";
import { PromotionHistoryComponent } from "../appraisal/promotion-history/promotion-history.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";


@Component({
  selector: "app-e-separation",
  templateUrl: "./e-separation.component.html",
  styleUrls: ["./e-separation.component.css"],
})
export class ESeparationComponent implements OnInit {
  user: any;
  lndUserType: any;
  name: any;
  showcard: Boolean;
  showhr: Boolean;
  emp: any;
  showPOA: boolean;
  id: any;
  showAmdoc: boolean;
  exitForm: FormGroup;
  teamData: any;
  whatsappNo: number;
  personalEmail: any;
  exitReason: any;
  empData: any;
  isHrManager :any;
  isManager :any;
  calculatedExitDate: any;
  proposedDate: string = "";
  comingProposedDate: string = "";
  exitFormId: number;
  showesep: boolean;
  showndcBtn: boolean;
  promotionData: any;
  empSeparation: any;
  canEditDate: boolean = true; 
  renderPromotion: boolean = false;
  showRemarkDiv: boolean;
  remark: string = '';
  showNoRecordsAvailable: boolean = false;
  leaveAdjustment: string = 'NA'; // Added leaveAdjustment property
  selectedUser: any = null; // Added selectedUser property
  approvalTypes: { [key: string]: string } = {}; // Initialize approvalTypes as an empty object
  approvalType: any ="NA";


  constructor(
    private learnerService: LearnerService,
    private loginService: LoginService,
    private appraisalService: AppraisalService,
    public loaderService: LoaderService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private eSeparationService: ESeparationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.learnerService.getUserType().subscribe(
      (res) => {
        this.user = res["lnd_user_type"];
        this.lndUserType = true;
        localStorage.setItem("user_type", res["lnd_user_type"]);
      },
      (error: any) => {
        console.log(error, error.error, error.message);
        if (error.error == "Signature has expired") {
          this.loginService.logout();
        }
      }
    );

    this.learnerService.getEmployeeDetails().subscribe(
      (res: any) => {
        this.emp = res.data;
        this.name = res.data.name;
        this.id = res.data.emp_id;
        // this.isHrManager = res.employee_permissions.hr_manager;
        // this.isManager = res.employee_permissions.manager;
        console.log(this.emp, "----------emp data");
      },
      (error: any) => {
        console.log(error, error.error, error.message);
        if (error.error == "Signature has expired") {
          this.loginService.logout();
        }
      }
    );

    this.eSeparationService.getTeamSeperation().subscribe(
      (res: any) => {
        console.log(res);
        this.teamData = res.data;
        this.checkExitForms();
      },
      (error: any) => {
        console.log(error, error.error, error.message);
        if (error.error == "Signature has expired") {
          this.loginService.logout();
        }
      }
    );
  }

  checkExitForms() {
    // Check if exit_forms array exists and has no data
    if (!this.teamData || this.teamData.length === 0) {
      this.showNoRecordsAvailable = true;
    }
  }




  // submitFormAcknowledge(action: string) {
  //   // Get values from the FormGroup
    
  
  //   // Combine with ngModel-bound properties
  //   const allData = {
  //   //  ...formValues, // Spreads exitReason, whatsAppNumber, personalEmail, exitDate
  //     proposedDate: this.proposedDate,
  //     approvalType: this.approvalType,
  //     remark: this.remark,
  //     action: action // Optional: include the action parameter ('Approved')
  //   };
  
  //   // Create a readable string for the alert
  //   let alertMessage = '';
  //   for (const [key, value] of Object.entries(allData)) {
  //     alertMessage += `${key}: ${value}\n`;
  //   }
  
  //   // Display the alert
  //   alert(alertMessage);
  
  //   // Add any additional logic here, e.g., submitting data to a service
  // }
  

  submitFormAcknowledge(status: string): void {
    const data = {
      proposed_date: this.proposedDate ? this.proposedDate : this.calculatedExitDate,
      leave_adjustment: this.approvalType,
      remark: this.remark,
      exit_form_id: this.exitFormId,
      status: status,
    };

   
//alert(JSON.stringify(data))
    this.eSeparationService.acknowledgeForm(data).subscribe({
      next: (response: any) => {
        console.log("Response:", response);

        if (response && response.status === true) {
          window.location.reload();
        } else {
          alert(response.message);
        }
      },
      error: (error) => console.error("Error:", error),
    });
  }

  onDateChange(event: any): void {
    console.log('Proposed Date:', event); // Debugging line
    this.proposedDate = event;
    if (event !== this.calculatedExitDate) {
      this.showRemarkDiv = true;
    } else {
      this.showRemarkDiv = false;
    }
    this.cdr.detectChanges(); // Manually trigger change detection
  }

  resetFormProperties() {
    this.remark = '';
    this.proposedDate = '';
    this.leaveAdjustment = '';
    this.showRemarkDiv = false;
  }

getEmployeeExitDetails(id, user: any) {
  // Reset form properties
  this.remark = ''; // Reset remark
  this.proposedDate = ''; // Reset proposed date
  this.leaveAdjustment = ''; // Reset selected car
  this.showRemarkDiv = false; // Hide remark div

  // Set the exit form ID and selected user
  this.exitFormId = id;
  this.selectedUser = user;

  // Fetch employee exit details
  this.eSeparationService.employeeExitDetails(id).subscribe(
    (res: any) => {
      console.log(res);
      const {
        exit_reason,
        personal_email,
        whatsapp_no,
        calculated_exit_date,
        approval_type_list,
        proposed_date,
        exit_remarks,
        approval_type,
        is_manager,
        is_hr,


        
      } = res.data;
      this.empData = res.data;
      this.exitReason = exit_reason;
      this.personalEmail = personal_email;
      this.whatsappNo = whatsapp_no;
      this.calculatedExitDate = calculated_exit_date;
      
      this.approvalTypes = approval_type_list; 
      this.comingProposedDate = proposed_date !== null && proposed_date !== undefined ? proposed_date : calculated_exit_date;
      this.remark = exit_remarks;
      this.approvalType=approval_type;
      this.isHrManager = is_hr;
      this.isManager = is_manager;
    },
    (error: any) => {
      console.log(error, error.error, error.message);
      if (error.error == "Signature has expired") {
        this.loginService.logout();
      }
    }
  );
}

  getSeparationEmpRequestsDetails(id) {
    this.eSeparationService.getSeparationEmpRequestsDetails(id).subscribe(
      (res: any) => {
        this.empSeparation = res.data;
        this.getPromotions(id);
      },
      (error: any) => {
        console.log(error, error.error, error.message);
        if (error.error == "Signature has expired") {
          this.loginService.logout();
        }
      }
    );
  }

  getPromotions(id) {
    this.eSeparationService.getExitPortalPromotion(id).subscribe(
      (res: any) => {
        this.renderPromotion = true;
        this.promotionData = res.data;
      },
      (error: any) => {
        console.log(error, error.error, error.message);
        if (error.error == "Signature has expired") {
          this.loginService.logout();
        }
      }
    );
  }

  openRemarkPopup(user: any) {
    this.selectedUser = user;
  }

  closeRemarkPopup() {
    this.selectedUser = null;
  }

  expensecardshow(showclaimdata) {
    this.showcard = showclaimdata;
  }

  hrmanagercardshow(showhrmanagerdata) {
    this.showhr = showhrmanagerdata;
  }

  showEseparation(showEseparationdata) {
    this.showesep = showEseparationdata;
  }

  showNDCBtn(showNDCData) {
    this.showndcBtn = showNDCData;
  }

  poashow(showpoa) {
    this.showPOA = showpoa;
  }

  amdocshow(showamdoc) {
    this.showAmdoc = showamdoc;
  }
   // Helper function to get keys of an object
   objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}